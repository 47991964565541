import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"
import { useNetworkState } from 'react-use';
import Login_footer from "./Login_footer";
import ReactLoading from 'react-loading';


const Customer_Login = () => {
  let navigate = useNavigate();
  let userDetail = localStorage.getItem('ship_rocket_user');
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" , type:config.type });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"" });
  const [modalState, setModalState] = useState({ show: false });
  const [typeState, setTypeState] = useState({ type: "login" });
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
  const[trackresult,setTrackresult] = useState({})
  const[errorState,setErrorState] = useState()
  const[isLoading,setisLoading] = useState(false)

  

  
  
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
  // const inputHandleChangeR = (e) => {
  //   const value = e.target.value;
  //   const text = e.target.name;
  //   setRegsterState({ ...regsterState, [text]: value })
  // }
  // const inputHandletracking = (e) => {
  //   const value = e.target.value;
  //   const text = e.target.name;
  //   setTrackState({ ...trackState, [text]: value })
  // }

  const loginButton = (z) => {
    setisLoading(true)
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // let headers = config.headers
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers:config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            setModalState({ show: false })
            navigate('/customer/dashboard')
            setisLoading(false)
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Email or password incorrect",
            color:"white"
          });
          setisLoading(false)

           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
    setisLoading(false)
  }
}

const naviagte_to_forgot = ()=>{
  if(state.email != "" ) {
    let full_api = config.apiUrl + `/user/forgot_password`;
    let sendData = {email:state.email};
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            // localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            // setUserState({full_name:res.data.output.full_name})
            // config.UserId = res.data.output.customer_id
            // setModalState({ show: false })
            Toast.fire({
              background:"#206bc4",
              type: 'success',
              title: res.data.message ,
              color:"white"
            });
    }
    else{

    }
  })
    let sendState = {email:state.email};
    // console.log("sendState === " , sendState)
  
     navigate('/customer/forgot-otp',{state:sendState})
  }
  else{
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please Enter Email",
      color:"white"
    });
  }
}

// const regitserButton = () =>{
//   // console.log("regsterState", regsterState);
//   if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "") {
//     let full_api = config.apiUrl + `/user/register_api`;
//     let sendData = regsterState;
//     // console.log("bb", sendData);
//     axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
//         // console.log("res ", res);
//         if (res.data.isValid) {
          
//             localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
//             setUserState({full_name:res.data.output.full_name})
//             setModalState({ show: false })
//            // navigate('/')
//         }else {
//           Toast.fire({
//             background:"rgb(231, 91, 75)",
//             type: 'unsuccess',
//             title: res.data.message ,
//             color:"white"
//           });
           
//         }

//     }).catch((e) => {


//     });
//   }else {
//     Toast.fire({
//       background:"rgb(231, 91, 75)",
//       type: 'unsuccess',
//       title: "Please fill all fields",
//       color:"white"
//     });
//   }

// }
// const trackButton = () =>{
//   setisLoading(true)
  

//         setisLoading(false)
//          navigate(`/tracking/`+ trackState.tracking_no)
  
// }
// const logOutButton = () =>{
//   // console.log("userDetail ===>   ");
//   localStorage.removeItem('ship_rocket_user');
//   userDetail = null;
//   setUserState({full_name:""})

// }
// const changestyle=()=>{
//   setStyleState({styles:1})
// }
// const changeloginstyle=()=>{
//   setStyleState({styles:0})
// }
// const openregister=()=>{
//   setModalState({show:true})
//   setStyleState({styles:1})
// }
// const openlogin=()=>{
//   setModalState({show:true})
//   setStyleState({styles:0})
// }
  return (
    <div>
       {/* <section class="login_box_sec">
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <div class="logo pb-5">
                <h2><img src="../../img/parcel_post_image.png" class="img-fluid" /></h2>
            </div>
            <div class="card login_box border-0 shadow-sm py-4 rounded-5">
                <div class="card-body">
                    <form action=""  autocomplete="off">
                        <div class="mb-3 mt-3">
                          <label for="email" class="form-label">Email:</label>
                          <input type="email" class="form-control" id="email" placeholder="Enter email"  name="email" onChange={(e) => inputHandleChange(e)} autocomplete="new-username"  />
                        </div>
                        <div class="mb-3">
                          <label for="pwd" class="form-label">Password:</label>
                          <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="password" onChange={(e) => inputHandleChange(e)}  autocomplete="new-password" />
                        </div>
                    
                        <div class="text-center pt-3">
                            <div class="d-grid">
                              <a onClick={() =>
                       loginButton()} class="btn btn-primary btn-block">Login</a>
                            </div>
                            <div class="pt-4">
                                New user? <a href="/customer/registration" className="text-decoration-none">Sign Up Now</a>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </section> */}
    <section class=" register_box_00">
    <nav class="navbar navbar-expand-sm pt-4">   
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img src="/img/parcel_post_image.png" class="img-fluid" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
                
            </ul>
            <div class="d-flex">
                <a href="/customer/registration" class="btn btn-primary">Sign Up</a>
            </div>
            </div>
        </div>
    </nav>



  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-body">
            <h4>Login</h4>
            <small>Hey, Enter your details to sign into your account</small>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">email</i></span>
                        <input type="text" class="form-control form-control-lg" placeholder="Enter your email address" name="email" onChange={(e) => inputHandleChange(e)}/>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">vpn_key</i></span>
                        <input type="password" class="form-control form-control-lg" placeholder="Enter your password here" name="password" onChange={(e) => inputHandleChange(e)}/>
                    </div>
                </div>
            </div>
            <a onClick={(e)=>naviagte_to_forgot(e)} class="register_box_00_a text-decoration-none" style={{cursor:"pointer"}}>Having trouble in signing in?</a>

            <div class="d-grid py-4">
            {isLoading==false?  <a  class="btn btn-primary btn-block" onClick={() =>
                       loginButton()}>Login</a>:<div className='' style={{marginLeft:"150px"}}><ReactLoading type={"spin"} color={"black"} height={40} width={30}/></div>}
            </div>

            <a href="/customer/registration" class="register_box_00_a text-decoration-none"> Sign Up here</a>
          </div>
        </div>
<Login_footer/>
        {/* <?php include 'login_footer.php';?> */}
      </div>
    </div>      
  </div>
</section>
    </div>
  )
}

export default Customer_Login