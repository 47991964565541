import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"

import { useLocation } from 'react-router-dom';
import { useNetworkState } from 'react-use';
import Login_footer from "./Login_footer";

const Verify_email = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    const location = useLocation();
    // console.log("location",location);
    //location.state.email
    const [userState, setUserState] = useState({ full_name: "" });
    const [state, setState] = React.useState({ email: location.state.email,otp:"" , mobile_number:location.state.mobile_number })
    const initialTimeInSeconds = 120; // 2 minutes
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [timerActive, setTimerActive] = useState(true); // State to track whether the timer is active
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });

    useEffect(() => {
        let timerInterval;

        if (timerActive) {
            timerInterval = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft((prevTime) => prevTime - 1);
                } else {
                    setTimerActive(false); // Timer has reached 0, so deactivate it
                }
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const handleResendClick = () => {
        // Reset the timer and make it active again
        setTimeLeft(initialTimeInSeconds);
        setTimerActive(true);
        let full_api = config.apiUrl + `/user/otp_resend`;
        // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
         let sendData = { email: state.email, otp: state.otp };
         // console.log(sendData);
         axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
           // console.log(res);
           if (res.data.status == true) {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
              let sendState = { mobile_number:sendData.mobile_number};
              // console.log("sendState === " , sendState)
            
            //    navigate('/customer/verify_sms',{state:sendState})
           }else {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
           
         }).catch((e) => {
           //setState({...state,isLoading: false});
 
           // toast.configure();
           // toast.error("Some thing went wrong");
           // // console.log("----error:   ", e);
         });
    };
    
    const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
  
      const axios_registration_api = (event) => {
        event.preventDefault();
        if (state.email != '') {
          let full_api = config.apiUrl + `/user/otp_verification`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = { email: state.email, otp: state.otp , mobile_number:state.mobile_number };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            // console.log("response11",res);
        //    if(res.data.status==true){
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //       let sendState = { mobile_number:sendData.mobile_number};
        //       // console.log("sendState === " , sendState)
            
        //        navigate('/customer/verify_sms',{state:sendState})
        //    }
        //    else{
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //    }
        if(res.data.status==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
           Toast.fire({
               background:"#206bc4",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             setTimeout(()=>{
               navigate('/customer/kyc')
             },2000)
           }
           else{
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
      const Otp_resend = () => {
        // console.log("----otp resend:   ");
      
      }
  return (
    <div>
    

{/* <section class="verify_email_box_sec">
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="logo pb-5">
            <h2><img src="../../img/parcel_post_image.png" class="img-fluid" /></h2>
        </div>
        <div class="card login_box border-0 shadow-sm py-4 px-5 rounded-5">
            <div class="card-body">
                <div class="alert alert-info mb-4" role="alert">
                    We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.
                </div>
               
                    <div class="mb-3 d-flex justify-content-center">
                    <input type="text" maxLength="6" className="form-control  " id="" placeholder="XXXXXX" required  style={{width:90}} name="otp" onChange={(e)=>handleChangeBroker(e)}/>
                     
                    </div>
                    <div class="text-center pt-3">
                        <div class="d-grid">
                            <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Verify Email</a>
                        </div>
                        <div class="pt-4">
                        Didn't receive OTP? {timerActive ? (
                                        <span id="resendTimer">Resend OTP in {formatTime(timeLeft)} </span>
                                    ) : (
                                        <button onClick={handleResendClick} className="btn btn-primary">Resend OTP</button>
                                    )}
                        </div>
                    </div>
               
            </div>
        </div>
    </div>
</section> */}

<section class=" register_box_00">
    <nav class="navbar navbar-expand-sm pt-4">   
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img src="/img/parcel_post_image.png" class="img-fluid" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
                
            </ul>
            <div class="d-flex">
                
            </div>
            </div>
        </div>
    </nav>



  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-body">
            <h4>Verify Email address</h4>
            <small>Hey, We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.</small>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">sms</i></span>
                        <input type="number" class="form-control form-control-lg" placeholder="Enter OTP" required  name="otp" onChange={(e)=>handleChangeBroker(e)}/>
                    </div>
                </div>
            </div>
            

            <div class="d-grid py-4">
              <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Verify Email</a>
            </div>

            <small>Didn't get OTP?  {timerActive ? (
                                      <strong><a  class="register_box_00_a">Resend OTP in {formatTime(timeLeft)} </a></strong>
                                    ) : (
                                        <strong><a onClick={handleResendClick}  class="register_box_00_a">Resend OTP</a></strong>
                                      
                                    )}</small>
          </div>
         
        </div> <Login_footer/>

        {/* <?php include 'login_footer.php';?> */}
      </div>
    </div>      
  </div>
</section>
   

{/* // <?php include 'footer.php';?> */}

    </div>
  )
}

export default Verify_email